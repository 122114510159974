/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.4.1/dist/css/bootstrap.min.css
 * - /npm/toastr@2.1.4/build/toastr.min.css
 * - /npm/animate.css@4.1.1/animate.min.css
 * - /npm/jquery-nice-select@1.1.0/css/nice-select.min.css
 * - /npm/sweetalert2@10.3.7/dist/sweetalert2.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
